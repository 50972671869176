import axios from 'axios';
import { logThrowApiError } from '../../../libs/kk-api';
import { setValue } from '../../../libs/storage.js';

export const namespace = 'email-log';

export const EMAIL_LOG_STORE = {
    STATE: {
        LOADING: 'LOADING',
        EMAIL_LOG: 'EMAIL_LOG',
        EMAIL_LOG_FILTERS: 'EMAIL_LOG_FILTERS',
    },
    LOADING: {
        FETCHING_EMAIL_LOG: 'FETCHING_EMAIL_LOG',
    },
    ACTIONS: {
        FETCH_EMAIL_LOG: 'FETCH_EMAIL_LOG',
    },
    MUTATIONS: {
        SET_LOADING: 'SET_LOADING',
        SET_DATA: 'SET_DATA',
        LOAD_EMAIL_LOG_FILTERS_FROM_LOCAL_STORAGE: 'LOAD_EMAIL_LOG_FILTERS_FROM_LOCAL_STORAGE',
        UPDATE_EMAIL_LOG_FILTER: 'UPDATE_EMAIL_LOG_FILTER',
        RESET_EMAIL_LOG_FILTERS: 'RESET_EMAIL_LOG_FILTERS',
    },
};

const defaultEmailLogFilters = () => {
    return {
        whereSearch: null,
        whereDate: null,
        whereInTo: null,
        whereInFromUserId: null,
        whereInType: null,
        whereInProjectId: null,
        columnFilters: {
            group_id: true,
            date: true,
            to: true,
            from: true,
            type: true,
            project: true,
            status: true,
            subject: true,
        },
    };
};

function initialState() {
    return {
        [EMAIL_LOG_STORE.STATE.LOADING]: {
            [EMAIL_LOG_STORE.LOADING.FETCHING_EMAIL_LOG]: false,
        },
        [EMAIL_LOG_STORE.STATE.EMAIL_LOG]: [],
        [EMAIL_LOG_STORE.STATE.EMAIL_LOG_FILTERS]: defaultEmailLogFilters(),
    };
}

const actions = {
    [EMAIL_LOG_STORE.ACTIONS.FETCH_EMAIL_LOG]: async (
        { commit, state },
        {
            companyId,
            display,
            page,
            whereSearch,
            orderBy,
            whereDate,
            whereInTo,
            whereInFromUserId,
            whereInType,
            whereInProjectId,
        },
    ) => {
        if (state[EMAIL_LOG_STORE.LOADING.FETCHING_EMAIL_LOG]) {
            return;
        }

        const url = `/api/companies/${companyId}/emails`;

        commit(EMAIL_LOG_STORE.MUTATIONS.SET_LOADING, { key: EMAIL_LOG_STORE.LOADING.FETCHING_EMAIL_LOG, value: true });

        try {
            const config = {
                params: {
                    display,
                    page,
                    whereSearch: whereSearch?.length ? whereSearch : null,
                    orderBy,
                    whereFromDate: whereDate?.start ? whereDate.start : null,
                    whereToDate: whereDate?.end ? whereDate.end : null,
                    whereInTo,
                    whereInFromUserId,
                    whereInType,
                    whereInProjectId,
                },
            };
            const result = await axios.get(url, config);

            commit(EMAIL_LOG_STORE.MUTATIONS.SET_DATA, {
                key: EMAIL_LOG_STORE.STATE.EMAIL_LOG,
                value: result.data,
            });
        } catch (err) {
            logThrowApiError(err, url);
        } finally {
            commit(EMAIL_LOG_STORE.MUTATIONS.SET_LOADING, {
                key: EMAIL_LOG_STORE.LOADING.FETCHING_EMAIL_LOG,
                value: false,
            });
        }
    },
};

const mutations = {
    [EMAIL_LOG_STORE.MUTATIONS.SET_LOADING]: (state, { key, value }) => {
        state[EMAIL_LOG_STORE.STATE.LOADING][key] = value;
    },
    [EMAIL_LOG_STORE.MUTATIONS.SET_DATA]: (state, { key, value }) => {
        state[key] = value;
    },
    [EMAIL_LOG_STORE.MUTATIONS.LOAD_EMAIL_LOG_FILTERS_FROM_LOCAL_STORAGE]: (state, filters) => {
        state[EMAIL_LOG_STORE.STATE.EMAIL_LOG_FILTERS] = filters ?? defaultEmailLogFilters();
    },
    [EMAIL_LOG_STORE.MUTATIONS.UPDATE_EMAIL_LOG_FILTER]: (state, { filter, userId }) => {
        // update email log filters local storage value for current user
        state[EMAIL_LOG_STORE.STATE.EMAIL_LOG_FILTERS] = {
            ...state[EMAIL_LOG_STORE.STATE.EMAIL_LOG_FILTERS],
            ...filter,
        };

        if (userId) {
            setValue('filters', state[EMAIL_LOG_STORE.STATE.EMAIL_LOG_FILTERS], userId, { namespace });
        }
    },
    [EMAIL_LOG_STORE.MUTATIONS.RESET_EMAIL_LOG_FILTERS]: (state, { userId }) => {
        state[EMAIL_LOG_STORE.STATE.EMAIL_LOG_FILTERS] = defaultEmailLogFilters();

        if (userId) {
            setValue('filters', state[EMAIL_LOG_STORE.STATE.EMAIL_LOG_FILTERS], userId, { namespace });
        }
    },
};

export const store = {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
