import axios from 'axios';
import { logThrowApiError } from '../../../libs/kk-api';

export const namespace = 'email-types';

export const EMAIL_TYPES_STORE = {
    STATE: {
        LOADING: 'LOADING',
        EMAIL_TYPES: 'EMAIL_TYPES',
    },
    ACTIONS: {
        FETCH_EMAIL_TYPES: 'FETCH_EMAIL_TYPES',
    },
    MUTATIONS: {
        SET_LOADING: 'SET_LOADING',
        SET_DATA: 'SET_DATA',
    },
};

function initialState() {
    return {
        [EMAIL_TYPES_STORE.STATE.LOADING]: false,
        [EMAIL_TYPES_STORE.STATE.EMAIL_TYPES]: [],
    };
}

const actions = {
    [EMAIL_TYPES_STORE.ACTIONS.FETCH_EMAIL_TYPES]: async ({ commit, state }) => {
        if (state[EMAIL_TYPES_STORE.STATE.LOADING]) {
            return;
        }

        const url = `/api/email-types`;

        commit(
            EMAIL_TYPES_STORE.MUTATIONS.SET_LOADING,
            true,
        );

        try {
            const result = await axios.get(url);

            commit(EMAIL_TYPES_STORE.MUTATIONS.SET_DATA, {
                key: EMAIL_TYPES_STORE.STATE.EMAIL_TYPES,
                value: result.data,
            });
        } catch (err) {
            logThrowApiError(err, url);
        } finally {
            commit(EMAIL_TYPES_STORE.MUTATIONS.SET_LOADING, false);
        }
    },
};

const mutations = {
    [EMAIL_TYPES_STORE.MUTATIONS.SET_LOADING]: (state, value) => {
        state[EMAIL_TYPES_STORE.STATE.LOADING] = value;
    },
    [EMAIL_TYPES_STORE.MUTATIONS.SET_DATA]: (state, { key, value }) => {
        state[key] = value;
    },
};

export const store = {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
