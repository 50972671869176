import _ from 'lodash';

// Actions
export const updateSelectedNodes = 'updateSelectedNodes';
export const toggleActiveNodeAction = 'toggleActiveNodeAction';
export const updateNodeAction = 'updateNodeAction';
export const addNodeAction = 'addNodeAction';
export const deleteNodeAction = 'deleteNodeAction';
export const duplicateNodeAction = 'duplicateNodeAction';
export const moveNodeAction = 'moveNodeAction';

// Mutations
export const SET_HOURS_TO_DISPLAY = 'SET_HOURS_TO_DISPLAY';
export const UPDATE_ACTIVE_NODE = 'UPDATE_ACTIVE_NODE';
export const ADD_MOVE_TO = 'ADD_MOVE_TO';
export const REMOVE_MOVE_TO = 'REMOVE_MOVE_TO';
export const DELETE_AND_OVERWRITE_ROOTS = 'DELETE_AND_OVERWRITE_ROOTS';
export const UPDATE_NODE_STATUS = 'UPDATE_NODE_STATUS';

/**
 * Recursively find a node by it's id
 * @param {null|Number} id
 * @param {Object} [node=state.tree] node
 * @returns {Object} node
 */
const findNode = (id, node = state.tree) => {
    // if received id is null, then return the state.tree
    // (assume we are working on a root node)
    if (id === null) {
        return node;
    }

    // Is the currrent node the one we want?
    if (node.id === id) {
        return node;
    } else if ('children' in node) {
        // If not, check it's children recursively to see if one of them is the one we are looking for
        // Go trough every child until we hit bottom
        for (let i = 0; i < node.children.length; i++) {
            const child = node.children[i];
            const notLast = findNode(id, child);

            if (notLast) {
                return notLast;
            }
        }
    } else {
        // The node we are inspecting has no children, return null to go back and see if it has siblings
        return null;
    }
};

const state = {
    tree: {
        children: [],
    },
    activeNode: {},
    selectedNodes: [],
    hoursToDisplay: 'effective',
    moveTo: [],
};

const mutations = {
    ADD_NODE: (state, { parent, node }) => {
        if (parent.id) {
            const parentNode = findNode(parent.id);
            parentNode.children.push(node);
        } else {
            parent.children.push(node);
        }

        state.tree = _.cloneDeep(state.tree);
    },

    DELETE_NODE: (state, { parent, nodeIndex }) => {
        if (parent.id) {
            const parentNode = findNode(parent.id);
            parentNode.children.splice(nodeIndex, 1);
        } else {
            parent.children.splice(nodeIndex, 1);
        }

        state.tree = _.cloneDeep(state.tree);
    },

    // TODO: Implement in app
    UPDATE_EXPANDED: (state, { node, expanded }) => {
        node.expanded = expanded;
    },

    // TODO: Implement in app
    UPDATE_SELECTABLE: (state, { node, selectable }) => {
        node.selectable = selectable;
    },

    ADD_MOVE_TO: (state, { id, multiple }) => {
        if (multiple === true) {
            state.moveTo.push(id);
        } else {
            state.moveTo = [id];
        }

        state.tree = _.cloneDeep(state.tree);
    },

    REMOVE_MOVE_TO: (state, { id, all }) => {
        if (id !== undefined) {
            const index = state.moveTo.findIndex(i => i === id);
            state.moveTo.splice(index, 1);
        } else if (all === true) {
            state.moveTo = [];
        }
        state.tree = _.cloneDeep(state.tree);
    },

    UPDATE_NODE_LEAF: (state, { parent, status }) => {
        if (parent.id) {
            const parentNode = findNode(parent.id);
            parentNode.is_leaf = status;
        } else {
            parent.is_leaf = status;
        }
    },

    UPDATE_NODE: (state, { node, data }) => {
        Object.assign(node, data);

        state.tree = _.cloneDeep(state.tree);
    },

    ADD_SELECTED_NODE: (state, id) => {
        if (!state.selectedNodes.includes(id)) {
            state.selectedNodes.push(id);
        }
        state.selectedNodes = _.cloneDeep(state.selectedNodes);
    },

    SET_HOURS_TO_DISPLAY(state, displayType) {
        state.hoursToDisplay = displayType;
    },

    DELETE_SELECTED_NODE: (state, index) => {
        state.selectedNodes.splice(index, 1);
        state.selectedNodes = _.cloneDeep(state.selectedNodes);
    },

    UPDATE_ACTIVE_NODE: (state, node) => {
        state.activeNode = node;
    },

    DELETE_AND_OVERWRITE_ROOTS: (state, roots) => {
        state.tree.children = roots;
    },
    UPDATE_NODE_STATUS: (state, { nodeId, status }) => {
        const node = findNode(nodeId);
        node.status = status;
    },

};

const actions = {

    toggleActiveNodeAction: ({ state, commit }, node) => {
        // If same node is selected, deselect it
        if (node.id === state.activeNode.id) {
            node = {};
        }
        commit('UPDATE_ACTIVE_NODE', node);
    },

    updateSelectedNodes: ({ state, commit }, { id, checked }) => {
        const removeSelectedChildren = (node) => {
            const nodeAlreadySelected = state.selectedNodes.includes(node.id);

            if (nodeAlreadySelected && node.id !== id) {
                const nodeIndex = state.selectedNodes.findIndex(selectedId => selectedId === node.id);
                commit('DELETE_SELECTED_NODE', nodeIndex);
            }

            if (node.children.length > 0) {
                node.children.forEach((node) => {
                    removeSelectedChildren(node);
                });
            }
        };

        if (checked) {
            commit('ADD_SELECTED_NODE', id);
            const node = findNode(id);

            if (node.children.length > 0) {
                removeSelectedChildren(node);
            }
        } else {
            const nodeIndex = state.selectedNodes.findIndex(selectedId => selectedId === id);
            commit('DELETE_SELECTED_NODE', nodeIndex);
        }
    },

    updateNodeAction: ({ commit }, data) => {
        let node = findNode(data.id);
        commit('UPDATE_NODE', { node, data });
    },

    addNodeAction: ({ state, commit }, node) => {
        const id = node.id;
        const parentId = node.parent_id;
        const frontEndData = {
            expanded: false,
            selectable: true,
        };

        node = { ...node, ...frontEndData };

        // If root node, add directly to tree and exit function
        if (parentId === null && node.real_depth === 0) {
            commit('ADD_NODE', { parent: state.tree, node: node });

            return;
        }

        // Node is not root-node. Find node's parent and push new node to parent's children array
        const parent = findNode(parentId);

        if (!parent) {
            throw 'Added node has no parent. This should not be possible';
        }

        const duplicate = parent.children.find(node => node.id === id);

        if (!duplicate) {
            commit('ADD_NODE', { parent, node: node });
        }
    },

    deleteNodeAction: ({ state, commit, dispatch }, node) => {
        const id = node.id;
        const parentId = node.parent_id;
        let parent = {};

        // Find direct parent object so we can remove the child from the children array
        if (parentId === null && node.real_depth === 0) {
            parent = state.tree;
        } else {
            // Node is not root-node. Find node's parent by recursion
            parent = findNode(parentId);
        }

        // Get deleted node's array index in his parent
        const nodeIndex = parent.children.findIndex(node => node.id === id);

        // Remove the deleted node from the selectedNodes array
        dispatch('updateSelectedNodes', { id, checked: false });

        // Remove from tree obj
        commit('DELETE_NODE', { parent, nodeIndex });

        // Make parent a leaf if it doesnt't have any children
        if (parent.children.length === 0) {
            commit('UPDATE_NODE_LEAF', { parent, status: true });
        }
    },

    duplicateNodeAction: ({ state, commit }, node) => {
        if (node.parent_id === null) {
            node['sum_estimate'] = node.estimate;
            node['sum_registered_horus'] = 0;
            node['sum_efficiency_hours'] = 0;
            commit('ADD_NODE', { parent: state.tree, node });
        } else {
            const parent = findNode(node.parent_id);
            commit('ADD_NODE', { parent, node });
        }
    },

    moveNodeAction: ({ commit }, { moveToId, node }) => {
        const nodeToBeMoved = findNode(node.id);
        const oldParent = findNode(nodeToBeMoved.parent_id);
        const oldNodeIndex = oldParent.children.findIndex(n => n.id === node.id);
        const newParent = findNode(moveToId);
        commit('DELETE_NODE', { parent: oldParent, nodeIndex: oldNodeIndex });

        if (oldParent.children.length === 0) {
            commit('UPDATE_NODE_LEAF', { parent: oldParent, status: true });
        }
        commit('ADD_NODE', { parent: newParent, node });
    },
};

export const hourBudget = {
    state,
    mutations,
    actions,
};
