import axios from 'axios';
import { logThrowApiError } from '../../../libs/kk-api';
import { setValue } from '../../../libs/storage.js';

export const namespace = 'cv';

const EMPLOYEE_URL = '/api/employee';
const CV_EMPLOYEE_URL = '/api/cv';
const CV_TYPES_URL = '/api/cv/settings';

export const CV_STORE = {
    STATE: {
        LOADING: 'LOADING',
        COURSE_TYPES: 'COURSE_TYPES',
        EXPERIENCE_TYPES: 'EXPERIENCE_TYPES',
        SCHOOL_TYPES: 'SCHOOL_TYPES',
        COMPETENCY_TYPES: 'COMPETENCY_TYPES',
        OTHER_TYPES: 'OTHER_TYPES',
        EMPLOYEE_INFO: 'EMPLOYEE_INFO',
        EMPLOYEE_COURSES: 'EMPLOYEE_COURSES',
        EMPLOYEE_EXPERIENCES: 'EMPLOYEE_EXPERIENCES',
        EMPLOYEE_SCHOOLS: 'EMPLOYEE_SCHOOLS',
        EMPLOYEE_COMPETENCIES: 'EMPLOYEE_COMPETENCIES',
        EMPLOYEE_OTHERS: 'EMPLOYEE_OTHERS',
        EMPLOYEE_ABOUT: 'EMPLOYEE_ABOUT',
        CV_LIST: 'CV_LIST',
        CV_LIST_FILTERS: 'CV_LIST_FILTERS',
    },
    LOADING: {
        FETCHING_TYPES: 'FETCHING_TYPES',
        DELETING_TYPE: 'DELETING_TYPE',
        EDITING_TYPE: 'EDITING_TYPE',
        CREATING_TYPE: 'CREATING_TYPE',
        MOVING_TYPE: 'MOVING_TYPE',
        FETCHING_EMPLOYEE: 'FETCHING_EMPLOYEE',
        FETCHING_CV_LIST: 'FETCHING_CV_LIST',
        CREATING_EMPLOYEE_ENTRY: 'CREATING_EMPLOYEE_ENTRY',
        UPDATING_EMPLOYEE_ENTRY: 'UPDATING_EMPLOYEE_ENTRY',
        DELETING_EMPLOYEE_ENTRY: 'DELETING_EMPLOYEE_ENTRY',
        ATTACH_FILE_TO_RESOURCE: 'ATTACH_FILE_TO_RESOURCE',
        DETACH_FILE_FROM_RESOURCE: 'DETACH_FILE_FROM_RESOURCE',
        SEND_EMAIL: 'SEND_EMAIL',
    },
    ACTIONS: {
        FETCH_TYPES: 'FETCH_TYPES',
        DELETE_TYPE: 'DELETE_TYPE',
        EDIT_TYPE: 'EDIT_TYPE',
        CREATE_TYPE: 'CREATE_TYPE',
        MOVE_TYPE: 'MOVE_TYPE',
        FETCH_EMPLOYEE: 'FETCH_EMPLOYEE',
        FETCH_CV_LIST: 'FETCH_CV_LIST',
        CREATE_EMPLOYEE_ENTRY: 'CREATE_EMPLOYEE_ENTRY',
        UPDATE_EMPLOYEE_ENTRY: 'UPDATE_EMPLOYEE_ENTRY',
        DELETE_EMPLOYEE_ENTRY: 'DELETE_EMPLOYEE_ENTRY',
        ATTACH_FILE_TO_RESOURCE: 'ATTACH_FILE_TO_RESOURCE',
        DETACH_FILE_FROM_RESOURCE: 'DETACH_FILE_FROM_RESOURCE',
        SEND_EMAIL: 'SEND_EMAIL',
    },
    MUTATIONS: {
        SET_LOADING: 'SET_LOADING',
        SET_DATA: 'SET_DATA',
        LOAD_CV_LIST_FILTERS_FROM_LOCAL_STORAGE: 'LOAD_CV_LIST_FILTERS_FROM_LOCAL_STORAGE',
        UPDATE_CV_LIST_FILTER: 'UPDATE_CV_LIST_FILTER',
        RESET_CV_LIST_FILTERS: 'RESET_CV_LIST_FILTERS',
        UPDATE_CV_RESOURCE_FILES: 'UPDATE_CV_RESOURCE_FILES',
        REMOVE_CV_RESOURCE_FILE: 'REMOVE_CV_RESOURCE_FILE',
    },
};

const defaultCVListFilters = () => {
    return {
        search: null,
        whereCompetencyTypeIds: null,
        whereCourseTypeIds: null,
        whereEducationTypeIds: null,
        whereExperienceTypeIds: null,
        whereOtherTypeIds: null,
        whereTeamTypeIds: null,
        wherePositionTypeIds: null,
        whereCompetencyOperator: 'AND',
        whereCourseOperator: 'AND',
        whereEducationOperator: 'AND',
        whereExperienceOperator: 'AND',
        whereOtherOperator: 'AND',
        columnFilters: {
            team: true,
            position: true,
            competencies: true,
            courses: true,
            experiences: true,
            others: false,
            educations: true,
            employee_id: true,
        },
    };
};

function toTypeKey(type) {
    switch (type) {
        case 'courses':
            return CV_STORE.STATE.COURSE_TYPES;
        case 'experience':
            return CV_STORE.STATE.EXPERIENCE_TYPES;
        case 'education':
            return CV_STORE.STATE.SCHOOL_TYPES;
        case 'competency':
            return CV_STORE.STATE.COMPETENCY_TYPES;
        case 'other':
            return CV_STORE.STATE.OTHER_TYPES;
        default:
            throw new Error(`Unknown type: ${type}`);
    }
}

function toEmployeeKey(type) {
    switch (type) {
        case 'courses':
            return CV_STORE.STATE.EMPLOYEE_COURSES;
        case 'experience':
            return CV_STORE.STATE.EMPLOYEE_EXPERIENCES;
        case 'education':
            return CV_STORE.STATE.EMPLOYEE_SCHOOLS;
        case 'competency':
            return CV_STORE.STATE.EMPLOYEE_COMPETENCIES;
        case 'other':
            return CV_STORE.STATE.EMPLOYEE_OTHERS;
        case 'about':
            return CV_STORE.STATE.EMPLOYEE_ABOUT;
        default:
            throw new Error(`Unknown type: ${type}`);
    }
}

function sortedByDate(items) {
    return items.sort((a, b) => moment(b.from).diff(moment(a.from)));
}

function sortedByTitle(items) {
    return items.sort((a, b) => a.title.localeCompare(b.title, 'no', { numeric: true, sensitivity: 'base' }));
}

function initialState() {
    return {
        [CV_STORE.STATE.LOADING]: {
            [CV_STORE.LOADING.FETCHING_TYPES]: false,
            [CV_STORE.LOADING.DELETING_TYPE]: false,
            [CV_STORE.LOADING.EDITING_TYPE]: false,
            [CV_STORE.LOADING.CREATING_TYPE]: false,
            [CV_STORE.LOADING.MOVING_TYPE]: false,
            [CV_STORE.LOADING.FETCHING_EMPLOYEE]: false,
            [CV_STORE.LOADING.FETCHING_CV_LIST]: false,
            [CV_STORE.LOADING.CREATING_EMPLOYEE_ENTRY]: false,
            [CV_STORE.LOADING.UPDATING_EMPLOYEE_ENTRY]: false,
            [CV_STORE.LOADING.DELETING_EMPLOYEE_ENTRY]: false,
            [CV_STORE.LOADING.ATTACH_FILE_TO_RESOURCE]: false,
            [CV_STORE.LOADING.DETACH_FILE_FROM_RESOURCE]: false,
            [CV_STORE.LOADING.SEND_EMAIL]: false,
        },
        [CV_STORE.STATE.COURSE_TYPES]: [],
        [CV_STORE.STATE.EXPERIENCE_TYPES]: [],
        [CV_STORE.STATE.SCHOOL_TYPES]: [],
        [CV_STORE.STATE.COMPETENCY_TYPES]: [],
        [CV_STORE.STATE.OTHER_TYPES]: [],
        [CV_STORE.STATE.EMPLOYEE_INFO]: null,
        [CV_STORE.STATE.EMPLOYEE_COURSES]: [],
        [CV_STORE.STATE.EMPLOYEE_EXPERIENCES]: [],
        [CV_STORE.STATE.EMPLOYEE_SCHOOLS]: [],
        [CV_STORE.STATE.EMPLOYEE_COMPETENCIES]: [],
        [CV_STORE.STATE.EMPLOYEE_OTHERS]: [],
        [CV_STORE.STATE.EMPLOYEE_ABOUT]: [],
        [CV_STORE.STATE.CV_LIST]: [],
        [CV_STORE.STATE.CV_LIST_FILTERS]: defaultCVListFilters(),
    };
}

const actions = {
    [CV_STORE.ACTIONS.FETCH_TYPES]: async ({ commit, state }) => {
        if (state[CV_STORE.LOADING.FETCHING_TYPES]) {
            return;
        }

        commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.FETCHING_TYPES, value: true });

        try {
            const fetchCourses = await axios.get(`${CV_TYPES_URL}/courses`);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.COURSE_TYPES,
                value: sortedByTitle(fetchCourses.data),
            });

            const fetchCompetency = await axios.get(`${CV_TYPES_URL}/competency`);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.COMPETENCY_TYPES,
                value: sortedByTitle(fetchCompetency.data),
            });

            const fetchExperiences = await axios.get(`${CV_TYPES_URL}/experience`);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.EXPERIENCE_TYPES,
                value: sortedByTitle(fetchExperiences.data),
            });

            const fetchEducation = await axios.get(`${CV_TYPES_URL}/education`);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.SCHOOL_TYPES,
                value: sortedByTitle(fetchEducation.data),
            });

            const fetchOther = await axios.get(`${CV_TYPES_URL}/other`);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.OTHER_TYPES,
                value: sortedByTitle(fetchOther.data),
            });
        } catch (err) {
            logThrowApiError(err, `${CV_TYPES_URL}`);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.FETCHING_TYPES, value: false });
        }
    },

    [CV_STORE.ACTIONS.DELETE_TYPE]: async ({ commit, state }, { id, type }) => {
        if (state[CV_STORE.LOADING.DELETING_TYPE]) {
            return;
        }

        commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.DELETING_TYPE, value: true });

        try {
            await axios.delete(`${CV_TYPES_URL}/${type}/${id}`);

            const key = toTypeKey(type);
            const filteredItems = state[key].filter(c => c.id !== id);

            commit(CV_STORE.MUTATIONS.SET_DATA, { key, value: sortedByTitle(filteredItems) });
        } catch (err) {
            logThrowApiError(err, `${CV_TYPES_URL}/${type}/${id}`);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.DELETING_TYPE, value: false });
        }
    },

    [CV_STORE.ACTIONS.EDIT_TYPE]: async ({ commit, state }, { id, type, title }) => {
        if (state[CV_STORE.LOADING.EDITING_TYPE]) {
            return;
        }

        commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.EDITING_TYPE, value: true });

        try {
            const key = toTypeKey(type);
            const response = await axios.put(`${CV_TYPES_URL}/${type}/${id}`, { title });

            const currentData = state[key];
            const index = currentData.findIndex(e => e.id === id);
            const newData = {
                ...currentData[index],
                ...response.data,
            };
            const newItems = currentData.toSpliced(index, 1, newData);

            commit(CV_STORE.MUTATIONS.SET_DATA, { key, value: sortedByTitle(newItems) });
        } catch (err) {
            logThrowApiError(err, `${CV_TYPES_URL}/${type}/${id}`, { title });
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.EDITING_TYPE, value: false });
        }
    },

    [CV_STORE.ACTIONS.CREATE_TYPE]: async ({ commit, state }, { type, title }) => {
        if (state[CV_STORE.LOADING.CREATING_TYPE]) {
            return;
        }

        commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.CREATING_TYPE, value: true });

        try {
            const key = toTypeKey(type);
            const response = await axios.post(`${CV_TYPES_URL}/${type}`, { title });

            const currentData = state[key];
            const newItems = currentData.toSpliced(0, 0, response.data);

            commit(CV_STORE.MUTATIONS.SET_DATA, { key, value: sortedByTitle(newItems) });
        } catch (err) {
            logThrowApiError(err, `${CV_TYPES_URL}/${type}`, { title });
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.CREATING_TYPE, value: false });
        }
    },

    [CV_STORE.ACTIONS.MOVE_TYPE]: async ({ commit, state }, { type, from, toType, toId }) => {
        if (state[CV_STORE.LOADING.MOVING_TYPE]) {
            return;
        }

        commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.MOVING_TYPE, value: true });

        try {
            const response = await axios.patch(`${CV_TYPES_URL}/${type}/move/${from}/${toType}/${toId}`);

            // Update item in old list
            const oldKey = toTypeKey(type);
            const currentData = state[oldKey];
            const oldFromIndex = currentData.findIndex(e => e.id === from);
            const oldResponseIndex = response.data.findIndex(e => e.id === from && e.type === type);
            const oldAdded = currentData.toSpliced(oldFromIndex, 1, response.data[oldResponseIndex]);
            commit(CV_STORE.MUTATIONS.SET_DATA, { key: oldKey, value: sortedByTitle(oldAdded) });

            // Update item in new list
            const newKey = toTypeKey(toType);
            const newData = state[newKey];
            const newFromIndex = newData.findIndex(e => e.id === toId);
            const responseNewIndex = response.data.findIndex(e => e.id === toId && e.type === toType);
            const newAdded = newData.toSpliced(newFromIndex, 1, response.data[responseNewIndex]);
            commit(CV_STORE.MUTATIONS.SET_DATA, { key: newKey, value: sortedByTitle(newAdded) });
        } catch (err) {
            logThrowApiError(err, `${CV_TYPES_URL}/${type}/${from}/${toType}/${toId}`);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.MOVING_TYPE, value: false });
        }
    },

    [CV_STORE.ACTIONS.FETCH_EMPLOYEE]: async ({ commit, state }, id) => {
        if (state[CV_STORE.LOADING.FETCHING_EMPLOYEE]) {
            return;
        }

        commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.FETCHING_EMPLOYEE, value: true });

        try {
            const config = {
                params: {
                    user: id,
                },
            };

            const fetchCourses = await axios.get(`${CV_EMPLOYEE_URL}/courses`, config);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.EMPLOYEE_COURSES,
                value: sortedByDate(fetchCourses.data),
            });

            const fetchCompetency = await axios.get(`${CV_EMPLOYEE_URL}/competency`, config);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.EMPLOYEE_COMPETENCIES,
                value: sortedByDate(fetchCompetency.data),
            });

            const fetchExperiences = await axios.get(`${CV_EMPLOYEE_URL}/experience`, config);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.EMPLOYEE_EXPERIENCES,
                value: sortedByDate(fetchExperiences.data),
            });

            const fetchEducation = await axios.get(`${CV_EMPLOYEE_URL}/education`, config);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.EMPLOYEE_SCHOOLS,
                value: sortedByDate(fetchEducation.data),
            });

            const fetchOther = await axios.get(`${CV_EMPLOYEE_URL}/other`, config);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.EMPLOYEE_OTHERS,
                value: sortedByDate(fetchOther.data),
            });

            const fetchAbout = await axios.get(`${CV_EMPLOYEE_URL}/about`, config);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.EMPLOYEE_ABOUT,
                value: sortedByDate(fetchAbout.data),
            });

            const fetchEmployee = await axios.get(`${EMPLOYEE_URL}/${id}?include[]=profile_image`);
            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.EMPLOYEE_INFO,
                value: fetchEmployee.data,
            });
        } catch (err) {
            logThrowApiError(err, `${CV_EMPLOYEE_URL}`);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.FETCHING_EMPLOYEE, value: false });
        }
    },

    [CV_STORE.ACTIONS.FETCH_CV_LIST]: async (
        { commit, state },
        {
            display,
            page,
            search,
            orderBy,
            whereCompetencyTypeIds,
            whereCourseTypeIds,
            whereEducationTypeIds,
            whereExperienceTypeIds,
            whereOtherTypeIds,
            whereTeamTypeIds,
            wherePositionTypeIds,
            whereCompetencyOperator,
            whereCourseOperator,
            whereEducationOperator,
            whereExperienceOperator,
            whereOtherOperator,
        },
    ) => {
        if (state[CV_STORE.LOADING.FETCHING_CV_LIST]) {
            return;
        }

        commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.FETCHING_CV_LIST, value: true });

        try {
            const config = {
                params: {
                    display,
                    page,
                    search: search?.length ? search : null,
                    orderBy,
                    whereCompetencyTypeIds,
                    whereCourseTypeIds,
                    whereEducationTypeIds,
                    whereExperienceTypeIds,
                    whereOtherTypeIds,
                    whereTeamTypeIds,
                    wherePositionTypeIds,
                    whereCompetencyOperator,
                    whereCourseOperator,
                    whereEducationOperator,
                    whereExperienceOperator,
                    whereOtherOperator,
                },
            };
            const fetchCVList = await axios.get(`${CV_EMPLOYEE_URL}`, config);

            commit(CV_STORE.MUTATIONS.SET_DATA, {
                key: CV_STORE.STATE.CV_LIST,
                value: fetchCVList.data,
            });
        } catch (err) {
            logThrowApiError(err, `${CV_EMPLOYEE_URL}`);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.FETCHING_CV_LIST, value: false });
        }
    },

    [CV_STORE.ACTIONS.CREATE_EMPLOYEE_ENTRY]: async ({ commit, state }, { type, data }) => {
        if (state[CV_STORE.LOADING.CREATING_EMPLOYEE_ENTRY]) {
            return;
        }

        commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.CREATING_EMPLOYEE_ENTRY, value: true });

        try {
            const response = await axios.post(`${CV_EMPLOYEE_URL}/${type}`, data);

            const key = toEmployeeKey(type);
            const currentData = state[key];
            const newItems = sortedByDate(currentData.toSpliced(0, 0, response.data));

            commit(CV_STORE.MUTATIONS.SET_DATA, { key, value: newItems });
        } catch (err) {
            logThrowApiError(err, `${CV_EMPLOYEE_URL}`, data);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.CREATING_EMPLOYEE_ENTRY, value: false });
        }
    },

    [CV_STORE.ACTIONS.UPDATE_EMPLOYEE_ENTRY]: async ({ commit, state }, { type, id, data }) => {
        if (state[CV_STORE.LOADING.UPDATING_EMPLOYEE_ENTRY]) {
            return;
        }

        commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.UPDATING_EMPLOYEE_ENTRY, value: true });

        try {
            const response = await axios.put(`${CV_EMPLOYEE_URL}/${type}/${id}`, data);

            const key = toEmployeeKey(type);
            const currentData = state[key];
            const index = currentData.findIndex(e => e.id === id);
            const newItems = sortedByDate(currentData.toSpliced(index, 1, response.data));

            commit(CV_STORE.MUTATIONS.SET_DATA, { key, value: newItems });
        } catch (err) {
            logThrowApiError(err, `${CV_EMPLOYEE_URL}`, data);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.UPDATING_EMPLOYEE_ENTRY, value: false });
        }
    },

    [CV_STORE.ACTIONS.DELETE_EMPLOYEE_ENTRY]: async ({ commit, state }, { id, type }) => {
        if (state[CV_STORE.LOADING.DELETING_EMPLOYEE_ENTRY]) {
            return;
        }

        commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.DELETING_EMPLOYEE_ENTRY, value: true });

        try {
            await axios.delete(`${CV_EMPLOYEE_URL}/${type}/${id}`);
            const key = toEmployeeKey(type);
            const currentData = state[key];
            const index = currentData.findIndex(e => e.id === id);
            const newItems = sortedByDate(currentData.toSpliced(index, 1));

            commit(CV_STORE.MUTATIONS.SET_DATA, { key, value: newItems });
        } catch (err) {
            logThrowApiError(err, `${CV_EMPLOYEE_URL}/${type}/${id}`);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.DELETING_EMPLOYEE_ENTRY, value: false });
        }
    },
    [CV_STORE.ACTIONS.ATTACH_FILE_TO_RESOURCE]: async ({ commit },
        {
            resourceId,
            resourceType,
            oldResourceType,
            fileId,
        },
    ) => {
        const url = `/api/files/${fileId}/attach-resource`;
        const body = {
            resource_id: resourceId,
            resource_type: resourceType,
        };

        try {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.ATTACH_FILE_TO_RESOURCE, value: true });

            const response = await axios.patch(url, body);

            // Update the DOM through the CV Store instead of
            // refreshing page
            commit(CV_STORE.MUTATIONS.UPDATE_CV_RESOURCE_FILES, {
                file: response.data,
                resourceId: resourceId,
                resourceType: oldResourceType,
            });
        } catch (error) {
            logThrowApiError(error, url, body, true);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.ATTACH_FILE_TO_RESOURCE, value: false });
        }
    },
    [CV_STORE.ACTIONS.DETACH_FILE_FROM_RESOURCE]: async ({ commit },
        {
            resourceId,
            resourceType,
            oldResourceType,
            attachmentId,
        },
    ) => {
        const url = `/api/files/${attachmentId}/detach-resource`;
        const body = {
            resource_id: resourceId,
            resource_type: resourceType,
        };

        try {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.DETACH_FILE_FROM_RESOURCE, value: true });

            const response = await axios.patch(url, body);

            //  Updating the DOM through the CV store
            commit(CV_STORE.MUTATIONS.REMOVE_CV_RESOURCE_FILE, {
                fileId: response.data.id,
                resourceType: oldResourceType,
                resourceId,
            });
        } catch (error) {
            logThrowApiError(error, url, body, true);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.DETACH_FILE_FROM_RESOURCE, value: false });
        }
    },
    [CV_STORE.ACTIONS.SEND_EMAIL]: async (
        { commit, rootGetters },
        { receivers, subject, content, copyToSelf, ids, merge, printParams = '', userId }) => {
        const body = {
            copy: copyToSelf,
            ids,
            subject,
            text: content,
            to: receivers.join(', '),
            projectId: rootGetters.currentProjectId,
            print: 1,
            merge: merge ? 1 : 0,
        };
        const url = `/personal/cv/${userId}/email?${printParams}`;

        try {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.SEND_EMAIL, value: true });

            return await axios.post(url, body);
        } catch (error) {
            logThrowApiError(error, url, body, true);
        } finally {
            commit(CV_STORE.MUTATIONS.SET_LOADING, { key: CV_STORE.LOADING.SEND_EMAIL, value: false });
        }
    },
};

const mutations = {
    [CV_STORE.MUTATIONS.SET_LOADING]: (state, { key, value }) => {
        state[CV_STORE.STATE.LOADING][key] = value;
    },
    [CV_STORE.MUTATIONS.SET_DATA]: (state, { key, value }) => {
        state[key] = value;
    },
    [CV_STORE.MUTATIONS.LOAD_CV_LIST_FILTERS_FROM_LOCAL_STORAGE]: (state, filters) => {
        state[CV_STORE.STATE.CV_LIST_FILTERS] = filters ?? defaultCVListFilters();
    },
    [CV_STORE.MUTATIONS.UPDATE_CV_LIST_FILTER]: (state, { filter, userId }) => {
        // update cv_list local storage value for current user
        state[CV_STORE.STATE.CV_LIST_FILTERS] = { ...state[CV_STORE.STATE.CV_LIST_FILTERS], ...filter };

        if (userId) {
            setValue('filters', state[CV_STORE.STATE.CV_LIST_FILTERS], userId, { namespace });
        }
    },
    [CV_STORE.MUTATIONS.RESET_CV_LIST_FILTERS]: (state, userId) => {
        // reset cv_list local storage value for current user
        const defaultFilters = {
            ...defaultCVListFilters(),
            columnFilters: state.CV_LIST_FILTERS.columnFilters,
        };

        setValue('filters', defaultFilters, userId, { namespace });
        state[CV_STORE.STATE.CV_LIST_FILTERS] = defaultFilters;
    },
    [CV_STORE.MUTATIONS.UPDATE_CV_RESOURCE_FILES]: (state, { resourceId, resourceType, file }) => {
        // Add a file to a cv resource without having to reload the whole page
        state[toEmployeeKey(resourceType)]
            .find(item => item.id === resourceId)
            .systemFiles.push(file);
    },
    [CV_STORE.MUTATIONS.REMOVE_CV_RESOURCE_FILE]: (state, { resourceId, resourceType, fileId }) => {
        // Remove a file from a cv resource without having to reload the whole page
        const files = state[toEmployeeKey(resourceType)].find(item => item.id === resourceId).systemFiles;
        const index = files.findIndex(file => file.id === fileId);

        files.splice(index, 1);
    },
};

export const store = {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
