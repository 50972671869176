import axios from 'axios';

import { formattedChecklistTitle, formattedProcedureTitle } from '../mixins/project-template-mixin';

export const namespace = 'project-templates';

export const PROJECT_TEMPLATE_STORE = {
    STATE: {
        LOADING: 'LOADING',
        DATA: 'DATA',
        LIST: 'LIST',
    },
    ACTIONS: {
        FETCH_LIST: 'FETCH_LIST',
        FETCH_DATA: 'FETCH_DATA',
        FETCH_SINGLE: 'FETCH_SINGLE',
        DELETE_TEMPLATE: 'DELETE_TEMPLATE',
        APPLY_TEMPLATES: 'APPLY_TEMPLATES',
    },
    MUTATIONS: {
        SET_LOADING: 'SET_LOADING',
        SET_DATA: 'SET_DATA',
        SET_LIST: 'SET_LIST',
    },
};

function initialState() {
    return {
        [PROJECT_TEMPLATE_STORE.STATE.LOADING]: false,
        [PROJECT_TEMPLATE_STORE.STATE.DATA]: {},
        [PROJECT_TEMPLATE_STORE.STATE.LIST]: [],
    };
}

const actions = {
    [PROJECT_TEMPLATE_STORE.ACTIONS.APPLY_TEMPLATES]: async ({ commit }, { onlyChecklists, items, projectId }) => {
        commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING, true);

        try {
            const method = 'POST';
            const url = '/api/project-templates/activate';
            const body = JSON.stringify({
                projectId,
                ids: items,
                onlyChecklists,
            });
            const redirect = 'manual';

            const headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            };

            // Need to use fetch here since axios does not support disabling redirects...
            return await fetch(url, { method, body, redirect, headers });
        } finally {
            commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING, false);
        }
    },

    [PROJECT_TEMPLATE_STORE.ACTIONS.FETCH_SINGLE]: async ({ commit }, idToFetch) => {
        commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING, true);

        try {
            const response = await axios.get(`/api/project-templates/${idToFetch}`);

            return response.data;
        } finally {
            commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING, false);
        }
    },

    [PROJECT_TEMPLATE_STORE.ACTIONS.DELETE_TEMPLATE]: async ({ commit }, idToDelete) => {
        commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING, true);

        try {
            await axios.delete(`/api/project-templates/${idToDelete}`);
        } finally {
            commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING, false);
        }
    },

    [PROJECT_TEMPLATE_STORE.ACTIONS.FETCH_LIST]: async ({ commit }) => {
        commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING, true);

        try {
            const result = await axios.get('/api/project-templates');
            commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LIST, result.data);
        } finally {
            commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING, false);
        }
    },

    [PROJECT_TEMPLATE_STORE.ACTIONS.FETCH_DATA]: async ({ commit }) => {
        commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING, true);

        try {
            const result = await axios.get('/api/project-templates/data');

            const unique = (value, index, array) => {
                return array.findIndex(item => item.bransjeId === value.bransjeId) === index;
            };

            const allIndustries = [...result.data.industries, ...result.data.companyIndustries].filter(unique);

            const content = {
                industries: result.data.industries
                    .sort((a, b) => a.navn.localeCompare(b.navn, 'nb', { sensitivity: 'base', numeric: true })),
                companyIndustries: result.data.companyIndustries
                    .sort((a, b) => a.navn.localeCompare(b.navn, 'nb', { sensitivity: 'base', numeric: true })),
                checklists: result.data.checklists
                    .map(checklist => ({
                        ...checklist,
                        fullTitle: formattedChecklistTitle(checklist, allIndustries),
                    }))
                    .sort((a, b) => a.fullTitle.localeCompare(b.fullTitle, 'en', { sensitivity: 'base', numeric: true })),
                documents: result.data.documents
                    .sort((a, b) => a.navn.localeCompare(b.navn, 'en', { sensitivity: 'base', numeric: true })),
                customForms: result.data.customForms
                    .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base', numeric: true })),
                adminCustomForms: result.data.adminCustomForms
                    .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base', numeric: true })),
                procedures: result.data.procedures
                    .sort((a, b) => formattedProcedureTitle(a).localeCompare(formattedProcedureTitle(b), 'en', { sensitivity: 'base', numeric: true })),
                mobileForms: result.data.mobileForms
                    .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base', numeric: true })),
            };

            commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_DATA, content);
        } finally {
            commit(PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING, false);
        }
    },
};

const mutations = {
    [PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LOADING]: (state, value) => {
        state[PROJECT_TEMPLATE_STORE.STATE.LOADING] = value;
    },
    [PROJECT_TEMPLATE_STORE.MUTATIONS.SET_LIST]: (state, list) => {
        state[PROJECT_TEMPLATE_STORE.STATE.LIST] = list;
    },
    [PROJECT_TEMPLATE_STORE.MUTATIONS.SET_DATA]: (state, data) => {
        state[PROJECT_TEMPLATE_STORE.STATE.DATA] = data;
    },
};

export const store = {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};
